import { colors, fonts } from "@theme/Themes";

import { lighten } from "@mui/material";
import styled from "styled-components";

interface ContainerProps {
  isWebTab?: boolean;
}

export const Container = styled.div<ContainerProps>`
  align-items: center;
  border: 1px solid ${colors.border};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 13px;
  margin-top: 32px;
  padding: 23px 19px;
  position: relative;

  ${({ isWebTab }) => {
    if (isWebTab) {
      return `
        border: none;
        margin: 0px;
        flex-direction: row;
        padding: 0px;

        button,
        #google-pay-button-container {
          flex: 1 1 calc(50% - 6.5px);

          &:last-of-type {
            &:first-of-type {
              flex: 1 0 48%;
            }
          }
        }

        @media screen and (max-width: 519px) {
          flex-wrap: wrap;
        }
      `;
    }
  }}

  .expressCheckoutBtn {
    background-color: ${colors.text};
    font-family: ${fonts.textFont};
    font-size: 1.5rem;
    gap: 4px;
    padding: 9px 10px;

    &:hover {
      background-color: ${lighten(colors.text, 0.2)};
    }

    .pay-icon {
      height: 26px;
      width: 26px;
    }

    &.Mui-disabled {
      background-color: ${colors.text};
      opacity: 0.5;
      font-weight: 500;
      height: 44px;
    }

    &.AmexPwPBtn {
      background-color: #006fcf;
      color: #ffffff;
      gap: 8px;

      .sup {
        font-size: 13px;
        margin-left: 2px;
      }
    }
  }

  .MuiAlert-standardError {
    margin: 8px 0;
  }
`;

export const Title = styled.span`
  background-color: ${colors.lighterBackground};
  color: ${colors.textDisabled};
  font-family: ${fonts.textFont};
  font-size: 1.5rem;
  font-weight: 400;
  left: 50%;
  min-width: 148px;
  padding: 0 12px;
  position: absolute;
  top: -14px;
  transform: translateX(-50%);
`;
